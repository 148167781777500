import React, { useState } from "react";
import Forget from "../component/forget/forget";

function ForgetPage() {
    return (
        <div className="main-container">
            <Forget/>
        </div>
    )
}

export default ForgetPage;