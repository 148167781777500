import React from "react";
import Cook from '../component/cookie/cookie';
import HelpButton from '../component/includes/helpbutton';

function CookieMng() {
    return (
        <>
            <Cook />
            <HelpButton />
        </>
    )
}

export default CookieMng;