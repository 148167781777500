import React from "react";
import Tryfree from "../component/tryfree/tryfree";
import HelpButton from '../component/includes/helpbutton';

function TryFreePage () {
    return(
        <div>
            <Tryfree/>
            <HelpButton/>
        </div>
    )
}

export default TryFreePage;